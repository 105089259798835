import React from 'react';
import PropTypes from 'prop-types';

const NetworkSettingsSublist = ({settings}) => {
    const vulnerableSettingsExist = () => {
        if (settings.http_three !== 'on' ||
        settings.ip_geolocation !== 'on') {
            return true;
        }

        return false;
    };

    return vulnerableSettingsExist() &&
        <fieldset className='rounded fieldset margin-bottom-2'>
            <legend className='lead font-bold'>Network Settings</legend>
            <table className='margin-0'>
                <thead>

                    <tr>
                        <th>Config</th>
                        <th style={{width: '33%'}}>Current Value</th>
                        <th style={{width: '33%'}}>Recommended Value</th>
                    </tr>
                </thead>
                <tbody>
                    {settings.http_three !== 'on' &&
                    <tr>
                        <td>HTTP/3</td>
                        <td className='alert-color'>{settings.http_three}</td>
                        <td>on</td>
                    </tr>}
                    {settings.ip_geolocation !== 'on' &&
                    <tr>
                        <td>IP Geolocation</td>
                        <td className='alert-color'>{settings.ip_geolocation}</td>
                        <td>on</td>
                    </tr>}
                </tbody>
            </table>
        </fieldset>;
};

NetworkSettingsSublist.propTypes = {
    settings: PropTypes.object.isRequired
};

export default NetworkSettingsSublist;