import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

/* global addParamToURL */
class Project extends PureComponent {
    constructor(props) {
        super(props);
        this.img = null;
    }

    componentDidMount() {
        if (this.img) {
            this.img.addEventListener('error', this.showMissingImage);
        }
    }

    componentWillUnmount() {
        if (this.img) {
            this.img.removeEventListener('error', this.showMissingImage);
        }
    }

    showMissingImage = () => {
        this.img.src = '/noimage.jpg';
    };

    render() {
        const {project, token, urlTypeToGo} = this.props;
        return (
            <div className='grid-x align-middle tight spaced bottom'>
                <div className='shrink cell'>
                    <span>{project.avatar_url
                        ? <img alt={project.name} className='thumb' ref={ref => this.img = ref}
                            src={addParamToURL(project.avatar_url, 'private_token', token)}/>
                        : <span className='empty-thumb'>{project.name.charAt(0).toUpperCase()}</span>
                    }</span>
                </div>
                <div className='auto cell'>
                    <span className='lead'>
                        <a
                            href={
                                urlTypeToGo === 'main_dir'
                                    ? `${project.web_url}/edit` : `${project.web_url}/`}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            {project.name}
                        </a>
                    </span>
                    {project.description && <small className='subheader'><br/>
                        {project.description}
                    </small>}
                </div>
            </div>
        );
    }
}

Project.propTypes = {
    project: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    urlTypeToGo: PropTypes.string.isRequired
};

export default Project;