import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

/* global addParamToURL */
class Group extends PureComponent {
    constructor(props) {
        super(props);
        this.img = null;
    }

    componentDidMount() {
        if (this.img) {
            this.img.addEventListener('error', this.showMissingImage);
        }
    }

    componentWillUnmount() {
        if (this.img) {
            this.img.removeEventListener('error', this.showMissingImage);
        }
    }

    showMissingImage = () => {
        this.img.src = '/noimage.jpg';
    };

    render() {
        const {group, token, urlTypeToGo} = this.props;
        return (
            <div className='grid-x align-middle'>
                <div className='shrink cell'>
                    <p>{group.avatar_url
                        ? <img alt={group.name} className='thumb' ref={ref => this.img = ref}
                            src={addParamToURL(group.avatar_url, 'private_token', token)}/>
                        : <span className='empty-thumb'>{group.name.charAt(0).toUpperCase()}</span>
                    }</p>
                </div>
                <div className='auto cell'>
                    <p className='nowrap'>
                        <span className='lead'>
                            <a
                                href={
                                    urlTypeToGo === 'main_dir'
                                        ? `${group.web_url}/-/edit` : `${group.web_url}/-/group_members`}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                {group.name}
                            </a>
                        </span>
                        {group.description && <small className='subheader'><br/>
                            {group.description}
                        </small>}
                    </p>
                </div>
            </div>
        );
    }
}

Group.propTypes = {
    group: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    urlTypeToGo: PropTypes.string.isRequired
};

export default Group;