const mergeRules = {
    MERGE_METHOD: 'ff',
    REMOVE_SOURCE_BRANCH_AFTER_MERGE: true,
    RESOLVE_OUTDATED_DIFF_DISCUSSIONS: true,
    PRINTING_MERGE_REQUEST_LINK_ENABLED: true,
    SQUASH_OPTION: 'always',
    ONLY_ALLOW_MERGE_IF_PIPELINE_SUCCEEDS: true,
    ONLY_ALLOW_MERGE_IF_ALL_DISCUSSIONS_ARE_RESOLVED: true
};

export const GitlabMergeRules = [
    {
        property: 'merge_method',
        expected: mergeRules.MERGE_METHOD,
        errorMessage: 'Merge Request: "merge_method"" must be set to "fast-forward"'
    },
    {
        property: 'remove_source_branch_after_merge',
        expected: mergeRules.REMOVE_SOURCE_BRANCH_AFTER_MERGE,
        errorMessage: 'Merge Request: "remove_source_branch_after_merge" must be enabled'
    },
    {
        property: 'squash_option',
        expected: mergeRules.SQUASH_OPTION,
        errorMessage: 'Merge Request: "squash_option" must be set to "always"'
    },
    {
        property: 'only_allow_merge_if_pipeline_succeeds',
        expected: mergeRules.ONLY_ALLOW_MERGE_IF_PIPELINE_SUCCEEDS,
        errorMessage: 'Merge Request: "only_allow_merge_if_pipeline_succeeds" must be enabled'
    },
    {
        property: 'only_allow_merge_if_all_discussions_are_resolved',
        expected: mergeRules.ONLY_ALLOW_MERGE_IF_ALL_DISCUSSIONS_ARE_RESOLVED,
        errorMessage: 'Merge Request: "only_allow_merge_if_all_discussions_are_resolved" must be enabled'
    }
];

const protectedBranchRules = {
    ALLOW_FORCE_PUSH: false,
    MERGE_ACCESS_LEVEL_DESCRIPTION: 'Maintainers',
    PUSH_ACCESS_LEVEL_DESCRIPTION: 'No one'
};

export const ProtectedBranchRules = [
    {
        property: 'allow_force_push',
        expected: protectedBranchRules.ALLOW_FORCE_PUSH,
        errorMessage: 'Protected Branch: "allow_force_push" must be disabled'
    },
    {
        property: 'merge_access_levels.0.access_level_description',
        expected: protectedBranchRules.MERGE_ACCESS_LEVEL_DESCRIPTION,
        errorMessage: 'Protected Branch: "merge_access_levels.access_level_description" must be set to "Maintainers"'
    },
    {
        property: 'push_access_levels.0.access_level_description',
        expected: protectedBranchRules.PUSH_ACCESS_LEVEL_DESCRIPTION,
        errorMessage: 'Protected Branch: "push_access_levels.access_level_description" must be set to "No one"'
    }
];