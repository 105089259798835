import React from 'react';
import PropTypes from 'prop-types';

const CacheSettingsSublist = ({settings}) => {
    const vulnerableSettingsExist = () => {
        if (settings.always_online !== 'on' ||
        settings.development_mode !== 'off' ||
        (isNaN(settings.browser_cache_ttl) || settings.browser_cache_ttl > 4) ||
        settings.tiered_caching !== 'on') {
            return true;
        }

        return false;
    };

    return vulnerableSettingsExist() &&
        <fieldset className='rounded fieldset margin-bottom-2'>
            <legend className='lead font-bold'>Cache Settings</legend>
            <table className='margin-0'>
                <thead>

                    <tr>
                        <th>Config</th>
                        <th style={{width: '33%'}}>Current Value</th>
                        <th style={{width: '33%'}}>Recommended Value</th>
                    </tr>
                </thead>
                <tbody>

                    {settings.always_online !== 'on' &&
                    <tr>
                        <td>Always Online</td>
                        <td className='alert-color'>{settings.always_online}</td>
                        <td>on</td>
                    </tr>}
                    {(isNaN(settings.browser_cache_ttl) || settings.browser_cache_ttl > 4) &&
                    <tr>
                        <td>Browser Cache TTL</td>
                        <td className='alert-color'>{settings.browser_cache_ttl}</td>
                        <td>4 hours or less</td>
                    </tr>}
                    {settings.development_mode !== 'off' &&
                    <tr>
                        <td>Development Mode</td>
                        <td className='alert-color'>{settings.development_mode}</td>
                        <td>off</td>
                    </tr>}
                    {settings.tiered_caching !== 'on' &&
                        <tr>
                            <td>Argo Tiered Cache</td>
                            <td className='alert-color'>{settings.tiered_caching}</td>
                            <td>on</td>
                        </tr>}
                </tbody>
            </table>
        </fieldset>;
};

CacheSettingsSublist.propTypes = {
    settings: PropTypes.object.isRequired
};

export default CacheSettingsSublist;