import React from 'react';
import PropTypes from 'prop-types';
import Member from './Member';

const Members = ({collection: members, token}) =>
    <div>
        <div className='grid-x align-right'>
            <div className='small-11 cell'>
                {members.sort((a, b) => a.name.localeCompare(b.name)).map(member =>
                    <Member key={member.id} member={member} token={token}/>)}
            </div>
        </div>
        <hr/>
    </div>;

Members.propTypes = {
    collection: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired
};

export default Members;