import React from 'react';
import PropTypes from 'prop-types';

const RulesSettingsSublist = ({settings}) => {
    const vulnerableSettingsExist = () => {
        if (settings.page_rules.length > 0 ||
            settings.normalize_incoming_urls !== 'on') {
            return true;
        }

        return false;
    };

    return vulnerableSettingsExist() &&
        <fieldset className='rounded fieldset margin-bottom-2'>
            <legend className='lead font-bold'>Rules Settings</legend>
            {settings.page_rules.length > 0 && settings.page_rules[0].error
                ? <div className='alert-color'>{settings.page_rules[0].error.message}</div>
                : <>
                    {settings.page_rules.length > 0 &&
                                <table className='margin-0'>
                                    <thead>
                                        <tr>
                                            <th>Target</th>
                                            <th style={{width: '66%'}}>Action(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {settings.page_rules.map(obj => {
                                            let urls = '';
                                            let actions = '';
                                            obj.targets.forEach((element, i) => {
                                                urls += element.constraint.value;

                                                if (i + 1 < obj.targets.length) {
                                                    urls += ', ';
                                                }
                                            });
                                            obj.actions.forEach((element, i) => {
                                                actions += `${element.id}:${JSON.stringify(element.value)}`;

                                                if (i + 1 < obj.targets.length) {
                                                    actions += ', ';
                                                }
                                            });
                                            return (
                                                <tr className='alert-color' key={obj.id}>
                                                    <td>{urls}</td>
                                                    <td>{actions}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                    }
                    {settings.normalize_incoming_urls !== 'on' &&
                            <>
                                <tr>
                                    <th>type</th>
                                    <th>setting</th>
                                    <th>recommended</th>
                                </tr>
                                <tr>
                                    <td>Normalize incoming URLs</td>
                                    <td className='alert-color'>{settings.normalize_incoming_urls}</td>
                                    <td>on</td>
                                </tr>
                            </>}

                </>}
        </fieldset>;
};

RulesSettingsSublist.propTypes = {
    settings: PropTypes.object.isRequired
};

export default RulesSettingsSublist;