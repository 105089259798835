import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* global Rails */
export default class PolicyTrainButton extends React.Component {
    constructor(props) {
        super(props);
        this.today = new Date().toISOString().slice(0, 10);
        this.state = {
            showForm: false, success: null, loading: false, trainedAt: this.props.trainedAt};
    }

    handleClick = () => {
        this.setState({showForm: true});
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({loading: true, showForm: false});
        Rails.ajax({
            url: `/processes/${this.props.policySlug}/users/${this.props.userId}`,
            type: 'post',
            dataType: 'json',
            data: new FormData(e.target),
            success: data => {
                this.setState({success: true, trainedAt: data.trained_at});
            },
            error: () => {
                this.setState({success: false});
            },
            complete: () => {
                this.setState({loading: false});
            }
        });
    };

    handleCancel = () => {
        this.setState({showForm: false});
    };

    renderForm = () =>
        <form
            action={`/processes/${this.props.policySlug}/users/${this.props.userId}`}
            method='post'
            onSubmit={this.handleSubmit}
        >
            <div className='grid-x align-center'>
                <div className='shrink cell'>
                    <div className='no-margin input-group'>
                        <input
                            className='input-group-field date'
                            defaultValue={this.today}
                            max={this.today}
                            name='trained_at'
                            required type='date'
                        />
                        <div className='input-group-button'>
                            <button
                                className='warning button'
                                onClick={this.handleCancel}
                                type='button'
                            >
                                <i className='fa fa-times'/>
                            </button>
                        </div>
                        <div className='input-group-button'>
                            <button
                                className='success button'
                                type='submit'
                            >
                                <i className='fa fa-check'/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>;

    renderButton = () =>
        <small>
            {this.state.trainedAt && <div>{moment(this.state.trainedAt).format('MMM D, YYYY')}</div>}
            {(!this.state.trainedAt || this.state.trainedAt < this.props.updatedAt) && <div>
                <a
                    className='hide-for-print'
                    onClick={this.handleClick}
                >
                    {this.state.trainedAt ? 'Update Training' : 'Record Training'}
                </a>
            </div>}
        </small>;

    render() {
        if (this.state.loading) {
            return <span><i className='fa fa-spinner fa-spin'/> saving...</span>;
        }
        else if (this.state.success === false) {
            return <span className='alert-color'><i className='fa fa-times'/> Error</span>;
        }

        return this.state.showForm ? this.renderForm() : this.renderButton();
    }
}

PolicyTrainButton.propTypes = {
    policySlug: PropTypes.string.isRequired,
    trainedAt: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired
};