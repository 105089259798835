import React from 'react';
import PropTypes from 'prop-types';

const FirewallSettingsSublist = ({settings}) => {
    const vulnerableSettingsExist = () => {
        if (settings.security_level !== 'high' ||
        (isNaN(settings.challenge_ttl) || settings.challenge_ttl > 30) ||
        settings.bot_management === false || settings.browser_check !== 'on' ||
        settings.privacy_pass !== 'on') {
            return true;
        }

        return false;
    };

    return vulnerableSettingsExist() &&
        <fieldset className='rounded fieldset margin-bottom-2'>
            <legend className='lead font-bold'>Firewall Settings</legend>
            <table className='margin-0'>
                <thead>
                    <tr>
                        <th>Config</th>
                        <th style={{width: '33%'}}>Current Value</th>
                        <th style={{width: '33%'}}>Recommended Value</th>
                    </tr>
                </thead>
                <tbody>
                    {settings.security_level !== 'high' &&
                    <tr>
                        <td>Security Level</td>
                        <td className={settings.security_level === 'medium'
                            ? 'warning-color'
                            : 'alert-color'
                        }>{settings.security_level}</td>
                        <td>high</td>
                    </tr>}
                    {(settings.challenge_ttl > 30 || isNaN(settings.challenge_ttl)) &&
                    <tr>
                        <td>Challenge Passage</td>
                        <td className='alert-color'>{isNaN(settings.challenge_ttl)
                            ? settings.challenge_ttl
                            : `${settings.challenge_ttl} minutes`}</td>
                        <td>30 minutes or less</td>
                    </tr>}
                    {settings.bot_management === false &&
                    <tr>
                        <td>Bot Fight Mode</td>
                        <td className='alert-color'>{settings.bot_management || 'off'}</td>
                        <td>on</td>
                    </tr>}
                    {settings.browser_check !== 'on' &&
                    <tr>
                        <td>Browser Integrity Check</td>
                        <td className='alert-color'>{settings.browser_check}</td>
                        <td>on</td>
                    </tr>}
                    {settings.privacy_pass !== 'on' &&
                    <tr>
                        <td>Privacy Pass Support</td>
                        <td className='alert-color'>{settings.privacy_pass}</td>
                        <td>on</td>
                    </tr>}
                </tbody>
            </table>
        </fieldset>;
};

FirewallSettingsSublist.propTypes = {
    settings: PropTypes.object.isRequired
};

export default FirewallSettingsSublist;