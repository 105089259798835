import React from 'react';
import axios from 'axios';
import {notify} from 'react-notify-toast';
import PropTypes from 'prop-types';
import SslSettingsSublist from './SslSettingsSublist';
import DnsSettingsSublist from './DnsSettingsSublist';
import FirewallSettingsSublist from './FirewallSettingsSublist';
import SpeedSettingsSublist from './SpeedSettingsSublist';
import CacheSettingsSublist from './CacheSettingsSublist';
import RulesSettingsSublist from './RulesSettingsSublist';
import NetworkSettingsSublist from './NetworkSettingsSublist';
import ScrapeShieldSettingsSublist from './ScrapeShieldSettingsSublist';

export default class SettingsAudit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            running: false,
            zones: {}
        };

        this.urlTypeToGO = 'main_dir';
    }

    run = () => {
        this.setState({
            running: true,
            zones: {}
        }, () => this.fetchSettings(this.props.token));
    };

    fetchSettings(token) {
        this.setState({running: true});
        axios.get('/api/cloudflare/settings', {headers: {Authorization: `Bearer ${token}`}}).then(response => {
            this.setState({zones: response.data});
        }).catch(error => {
            notify.show(error.response.data.error, 'error');
        }).finally(() => {
            this.setState({running: false});
        });
    }

    renderZones = () => {
        const {zones} = this.state;
        return zones.map(zone =>
            <div key={zone.id}>
                <div className='primary callout'>
                    <div className='grid-x align-middle'>
                        <div className='shrink cell'>
                            <h2 className='tight margin-right-1 margin-bottom-0'>
                                <a
                                    className={zone.status === 'active' ? 'primary-color' : 'alert-color'}
                                    href={`//${zone.name}`}
                                    rel='noopener noreferer noreferrer'
                                    target='_blank'>
                                    {zone.name}
                                </a>
                            </h2>
                        </div>
                        <div className='shrink cell'>
                            {zone.status === 'active'
                                ? <span className='primary label'>{zone.status}</span>
                                : <span className='warning label'>{zone.status}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className='tight margin-left-2'>
                    <SslSettingsSublist settings={zone.ssl_settings}/>
                    <DnsSettingsSublist settings={zone.dns_settings}/>
                    <FirewallSettingsSublist settings={zone.firewall_settings}/>
                    <SpeedSettingsSublist settings={zone.speed_settings}/>
                    <CacheSettingsSublist settings={zone.cache_settings}/>
                    <RulesSettingsSublist settings={zone.rules_settings}/>
                    <NetworkSettingsSublist settings={zone.network_settings}/>
                    <ScrapeShieldSettingsSublist settings={zone.scrape_shield_settings}/>
                </div>
            </div>);
    };

    render() {
        const {zones, running} = this.state;

        return (
            <div>
                {running && <p className='primary callout'>
                    <i className='fa fa-spinner fa-pulse'/> Running report...
                </p>}

                {!this.state.running && <div className='text-center'>
                    <a className='primary button' onClick={this.run}>Run Report</a>
                </div>}

                {zones.length > 0 && // eslint-disable-line no-nested-ternary
                     (zones[0].error
                         ? <div className='alert callout'>
                            error: {zones[0].error.message}
                         </div>
                         : <>
                             <div className='lead'>
                                Domains ({zones.length > 0 ? zones.length : 0})
                             </div>
                             <hr/>
                             {this.renderZones()}
                         </>)}
            </div>
        );
    }
}

SettingsAudit.propTypes = {
    token: PropTypes.string.isRequired
};