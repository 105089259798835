import React from 'react';
import PropTypes from 'prop-types';

const SslSettingsSublist = ({settings}) => {
    const vulnerableSettingsExist = () => {
        if (settings.error ||
        settings.ssl !== 'strict' ||
        !settings.strict_transport_security ||
        settings.always_use_https !== 'on' ||
        settings.automatic_https_rewrites !== 'on' ||
        parseFloat(settings.min_tls_version) < 1.2 ||
        settings.tls_1_3 !== 'on' ||
        settings.ssl_tls_recommender !== true ||
        settings.opportunistic_encryption !== 'on' ||
        settings.tls_client_auth !== 'on' ||
        settings.certificate_transparency) {
            return true;
        }

        return false;
    };

    return vulnerableSettingsExist() &&
        <fieldset className='rounded fieldset margin-bottom-2'>
            <legend className='lead font-bold'>SSL Settings</legend>
            {settings.error
                ? <div className='alert-color'>{settings.error.message}</div>
                : <table className='margin-0'>
                    <thead className='text-left'>
                        <tr>
                            <th>Config</th>
                            <th style={{width: '33%'}}>Current Value</th>
                            <th style={{width: '33%'}}>Recommended Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {settings.ssl !== 'strict' &&
                        <tr>
                            <td>SSL</td>
                            <td className={settings.ssl === 'full'
                                ? 'warning-color'
                                : 'alert-color'}>{settings.ssl}</td>
                            <td>full (strict)</td>
                        </tr>}
                        {!settings.strict_transport_security &&
                        <tr>
                            <td>HTTP Strict Transport Security (HSTS)</td>
                            <td className='alert-color'>disabled</td>
                            <td>enabled</td>
                        </tr>}
                        {settings.always_use_https !== 'on' &&
                        <tr>
                            <td>Always Use HTTPS</td>
                            <td className='alert-color'>{settings.always_use_https}</td>
                            <td>on</td>
                        </tr>}
                        {settings.automatic_https_rewrites !== 'on' &&
                        <tr>
                            <td>Automatic HTTPS Rewrites</td>
                            <td className='alert-color'>{settings.automatic_https_rewrites}</td>
                            <td>on</td>
                        </tr>}
                        {parseFloat(settings.min_tls_version) < 1.2 &&
                        <tr>
                            <td>Minimum TLS Version</td>
                            <td className='alert-color'>{settings.min_tls_version}</td>
                            <td>1.2 or greater</td>
                        </tr>}
                        {settings.tls_1_3 !== 'on' &&
                        <tr>
                            <td>TLS 1.3</td>
                            <td className='alert-color'>{settings.tls_1_3}</td>
                            <td>on</td>
                        </tr>}
                        {settings.ssl_tls_recommender !== true &&
                        <tr>
                            <td>SSL/TLS Recommender</td>
                            <td className='alert-color'>{settings.ssl_tls_recommender === false
                                ? 'disabled'
                                : settings.ssl_tls_recommender}</td>
                            <td>enabled</td>
                        </tr>}
                        {settings.opportunistic_encryption !== 'on' &&
                        <tr>
                            <td>Opportunistic Encryption</td>
                            <td className='alert-color'>{settings.opportunistic_encryption}</td>
                            <td>on</td>
                        </tr>}
                        {settings.tls_client_auth !== 'on' &&
                        <tr>
                            <td>Authenticated Origin Pulls</td>
                            <td className='alert-color'>{settings.tls_client_auth}</td>
                            <td>on</td>
                        </tr>}
                        {settings.certificate_transparency &&
                        <tr>
                            <td>Certificate Transparency Monitoring</td>
                            <td className='alert-color'>{settings.certificate_transparency === true
                                ? 'on'
                                : settings.certificate_transparency}</td>
                            <td>off</td>
                        </tr>}
                    </tbody>
                </table>}
        </fieldset>;
};

SslSettingsSublist.propTypes = {
    settings: PropTypes.object.isRequired
};

export default SslSettingsSublist;