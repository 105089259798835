import React from 'react';
import PropTypes from 'prop-types';

const SpeedSettingsSublist = ({settings}) => {
    const vulnerableSettingsExist = () => {
        if (settings.brotli !== 'on' ||
        settings.rocket_loader !== 'off' ||
        settings.minify.css !== 'on' ||
        settings.minify.html !== 'on' ||
        settings.minify.js !== 'on') {
            return true;
        }

        return false;
    };

    return vulnerableSettingsExist() &&
        <fieldset className='rounded fieldset margin-bottom-2'>
            <legend className='lead font-bold'>Speed Settings</legend>
            <table className='margin-0'>
                <thead>

                    <tr>
                        <th>Config</th>
                        <th style={{width: '33%'}}>Current Value</th>
                        <th style={{width: '33%'}}>Recommended Value</th>
                    </tr>
                </thead>
                <tbody>
                    {settings.brotli !== 'on' &&
                    <tr>
                        <td>Brotli</td>
                        <td className='alert-color'>{settings.brotli}</td>
                        <td>on</td>
                    </tr>}
                    {settings.rocket_loader !== 'off' &&
                    <tr>
                        <td>Rocket Loader</td>
                        <td className='warning-color'>{settings.rocket_loader}</td>
                        <td>off</td>
                    </tr>}
                    {settings.minify.css !== 'on' &&
                    <tr>
                        <td>Minify CSS</td>
                        <td className='alert-color'>{settings.minify.css}</td>
                        <td>on</td>
                    </tr>}
                    {settings.minify.html !== 'on' &&
                    <tr>
                        <td>Minify HTML</td>
                        <td className='alert-color'>{settings.minify.html}</td>
                        <td>on</td>
                    </tr>}
                    {settings.minify.js !== 'on' &&
                    <tr>
                        <td>Minify JS</td>
                        <td className='alert-color'>{settings.minify.js}</td>
                        <td>on</td>
                    </tr>}
                </tbody>
            </table>
        </fieldset>;
};

SpeedSettingsSublist.propTypes = {
    settings: PropTypes.object.isRequired
};

export default SpeedSettingsSublist;