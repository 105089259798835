import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

/* global addParamToURL */
class Member extends PureComponent {
    constructor(props) {
        super(props);
        this.img = null;
    }

    componentDidMount() {
        if (this.img) {
            this.img.addEventListener('error', this.showMissingImage);
        }
    }

    componentWillUnmount() {
        if (this.img) {
            this.img.removeEventListener('error', this.showMissingImage);
        }
    }

    showMissingImage = () => {
        this.img.src = '/nophoto.png';
    };

    render() {
        const {member, token} = this.props;
        return (
            <div className='grid-x align-middle'>
                <div className='shrink cell'>
                    <p>{member.avatar_url
                        ? <img alt={member.name} className='round thumb' ref={ref => this.img = ref}
                            src={addParamToURL(member.avatar_url, 'private_token', token)}/>
                        : <div className='empty-thumb'>{member.name.charAt(0).toUpperCase()}</div>
                    }</p>
                </div>
                <div className='auto cell'>
                    <p>
                        {member.name} <small className='subheader'>@{member.username}</small>
                        {member.expires_at && <small className='nowrap alert-color'>
                            <br/><b>Expires on {member.expires_at}</b>
                        </small>}
                    </p>
                </div>
            </div>
        );
    }
}

Member.propTypes = {
    member: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired
};

export default Member;