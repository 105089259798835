import React from 'react';
import PropTypes from 'prop-types';

const DnsSettingsSublist = ({settings}) => {
    const correspondingARecord = record => {
        for (let i = 0; i < settings.records.length; i++) {
            if (settings.records[i].name === record.content && settings.records[i].proxied &&
                (settings.records[i].type === 'A' || settings.records[i].type === 'AAAA')) {
                return true;
            }
        }

        return false;
    };

    const vulnerableRecordsExist = () => {
        let exists = false;
        settings.records.forEach(record => {
            if (record.proxiable && !record.proxied) {
                if (record.type === 'A' || record.type === 'AAAA') {
                    exists = true;
                }
                else if (record.type === 'CNAME') {
                    if (correspondingARecord(record)) {
                        exists = true;
                    }
                }
            }
        });

        if (settings.records.length > 0 && settings.records[0].error) {
            exists = true;
        }

        return exists;
    };

    const isVulnerableRecord = record => {
        if (record.proxiable && !record.proxied) {
            if (record.type === 'A' || record.type === 'AAAA') {
                return true;
            }
            else if (record.type === 'CNAME') {
                return correspondingARecord(record);
            }
        }

        return false;
    };

    return (vulnerableRecordsExist() || settings.dns_sec !== 'active') &&
            <fieldset className='rounded fieldset margin-bottom-2'>
                <legend className='lead font-bold'>DNS Settings</legend>
                {settings.records.length > 0 && settings.records[0].error
                    ? <div className='alert-color'>{settings.records[0].error.message}</div>
                    : <>
                        {settings.dns_sec !== 'active' &&
                        <table>
                            <thead>
                                <tr>
                                    <th>Config</th>
                                    <th style={{width: '33%'}}>Current Value</th>
                                    <th style={{width: '33%'}}>Recommended Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>DNSSEC</td>
                                    <td className='alert-color'>{settings.dns_sec}</td>
                                    <td>enabled</td>
                                </tr>
                            </tbody>
                        </table>}
                        {vulnerableRecordsExist() &&
                            <table className='margin-0'>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th style={{width: '33%'}}>Name</th>
                                        <th style={{width: '33%'}}>Proxy Status</th>
                                    </tr></thead>
                                <tbody>
                                    {settings.records.map(record => {
                                        if (isVulnerableRecord(record)) {
                                            return (
                                                <tr key={record.id}>
                                                    <td>{record.type}</td>
                                                    <td>{record.name}</td>
                                                    <td className='alert-color'>disabled</td>
                                                </tr>
                                            );
                                        }

                                        return null;
                                    })}
                                </tbody>
                            </table>}
                    </>}
            </fieldset>;
};

DnsSettingsSublist.propTypes = {
    settings: PropTypes.object.isRequired
};

export default DnsSettingsSublist;